import { Label, Button, Card, CardFooter, Input, Menu, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, SplitButton, useId, makeStyles, Divider, Toolbar, ToolbarButton, ToolbarDivider } from "@fluentui/react-components";
import EmailSignature from "./SignatureComponent";
import { TextBoldRegular, TextItalicRegular, TextUnderlineRegular, TextBulletListLtrRegular, TextNumberListLtrRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    field: {
        display: "flex",
    },
    editor: {
        maxHeight: "300px",
        minHeight: "200px",
        outlineColor: "transparent",
        overflowX: "scroll"
    }
});

export default function ConversationEditorComponent() {
    const toId = useId("input-to");
    const ccId = useId("input-cc");
    const styles = useStyles();

    const handleInsertList = (type: string) => {
        let listHTML;

        if (type === 'numbered') {
            listHTML = '<ol><li></li></ol>';
        } else if (type === 'bulleted') {
            listHTML = '<ul><li></li></ul>';
        } else {
            return; // Invalid type, do nothing
        }

        const selection = window.getSelection();

        if (selection) {
            const range = selection.getRangeAt(0);
            const listFragment = range.createContextualFragment(listHTML);

            range.deleteContents();
            range.insertNode(listFragment);
        }
    };

    const handleBold = () => {
        wrapSelectedText('strong');
    };
    const handleItalic = () => {
        wrapSelectedText('i');
    };
    const handleUnderline = () => {
        wrapSelectedText('u');
    };

    const wrapSelectedText = (tagName: string) => {
        const selection = window.getSelection();
        /*if (selection) {
            const range = selection.getRangeAt(0);
            const wrapper = document.createElement(tagName);
            range.surroundContents(wrapper);
        }*/

        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const tag = document.createElement(tagName);

            const isTagged = range.commonAncestorContainer.firstChild === tag;
    
            if (isTagged) {
                // Unwrap the selected text from the 'strong' tag
                const parent = range.commonAncestorContainer.parentElement;
                const grandparent = parent?.parentElement;
                if (grandparent) {
                    while (parent.firstChild) {
                        grandparent.insertBefore(parent.firstChild, parent);
                    }
                    grandparent.removeChild(parent);
                }
            } else {
                range.surroundContents(tag);
            }
        }
    };


    return (
        <Card>

            <div className={styles.field}>
                <Label htmlFor={toId}>To</Label>
                <Input style={{ flex: "1" }} appearance="underline" id={toId} />
            </div>
            <div className={styles.field}>
                <Label htmlFor={ccId}>Cc</Label>
                <Input style={{ flex: "1" }} appearance="underline" id={ccId} />
            </div>

            <Toolbar size="small">
                <ToolbarButton
                    aria-label="Bold"
                    icon={<TextBoldRegular />}
                    name="textOptions"
                    value="bold"
                    appearance="subtle"
                    onClick={handleBold}
                />
                <ToolbarButton
                    aria-label="Italic"
                    icon={<TextItalicRegular />}
                    name="textOptions"
                    value="italic"
                    appearance="subtle"
                    onClick={handleItalic}
                />
                <ToolbarButton
                    aria-label="Underline"
                    icon={<TextUnderlineRegular />}
                    name="textOptions"
                    value="underline"
                    appearance="subtle"
                    onClick={handleUnderline}
                />
                <ToolbarDivider />
                <ToolbarButton
                    aria-label="Bulleted List"
                    icon={<TextBulletListLtrRegular />}
                    name="textOptions"
                    appearance="subtle"
                    onClick={() => handleInsertList('bulleted')}
                />
                <ToolbarButton
                    aria-label="Numbered List"
                    icon={<TextNumberListLtrRegular />}
                    name="textOptions"
                    appearance="subtle"
                    onClick={() => handleInsertList('numbered')}
                />
            </Toolbar>
            <Divider />

            <div contentEditable className={styles.editor}>

                <br />
                <EmailSignature firstName={"John"} lastName={"Doe"} mainPosition={"Trainee"} secondaryPositions={undefined} email={"j.doe@lpehq.eu"} />
            </div>


            <Divider />
            <CardFooter>
                <Menu positioning="below-end">
                    <MenuTrigger disableButtonEnhancement>
                        {(triggerProps: MenuButtonProps) => (
                            <SplitButton
                                menuButton={triggerProps}
                                appearance="primary"
                            //primaryActionButton={primaryActionButtonProps}
                            >
                                Send
                            </SplitButton>
                        )}
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList>
                            <MenuItem>Send</MenuItem>
                            <MenuItem>Schedule</MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <Button>Request approval</Button>
            </CardFooter>

        </Card>
    );
}