import { Body1, Card, CardHeader, Divider, SelectTabData, SelectTabEvent, Tab, TabList, TabValue, Table, TableBody, TableCell, TableCellLayout, TableRow, makeStyles } from "@fluentui/react-components";
import { BookSearch24Regular, BriefcasePerson24Regular, CommunicationPerson24Regular, Mail24Regular, MailTemplate24Regular } from "@fluentui/react-icons";
import { useState } from "react";

const useStyles = makeStyles({
    root: {
        display: "flex",
    }
  });

export default function ConversationToolsComponent() {
    const styles = useStyles();
    
    const [selectedValue, setSelectedValue] = useState<TabValue>("profile");

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    return (
        <div className={styles.root}>
            <Card>
                <CardHeader 
                    header={
                        <Body1>
                            <b>
                            {selectedValue === "profile" && "Profile Search"}
                            {selectedValue === "knowledgebase" && "Knowledgebase"}
                            {selectedValue === "templates" && "Templates"}
                            </b>
                        </Body1>
                      }
                />
                <Divider />
                
                <Table size="extra-small" aria-label="Table with extra-small size">
                    <TableBody>
                        <TableRow key="name">
                            <TableCell>
                            <TableCellLayout media={<BriefcasePerson24Regular />}>
                                Name
                            </TableCellLayout>
                            </TableCell>
                            <TableCell>John Doe</TableCell>
                        </TableRow>
                        <TableRow key="email">
                            <TableCell>
                            <TableCellLayout media={<Mail24Regular />}>
                                E-Mail
                            </TableCellLayout>
                            </TableCell>
                            <TableCell>J.doe@lpehq.eu</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

            </Card>
            <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} vertical size="large">
                <Tab icon={<CommunicationPerson24Regular />} value="profile" aria-label="Profile" />
                <Tab icon={<BookSearch24Regular />} value="knowledgebase" aria-label="Knowledgebase" />
                <Tab icon={<MailTemplate24Regular />} value="templates" aria-label="Templates" />
            </TabList>
        </div>
    );
}