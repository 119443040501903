import { Body1, Button, Caption1, Card, CardFooter, CardHeader, Divider, makeStyles, shorthands } from "@fluentui/react-components";
import { Open16Regular, ShareScreenPersonOverlayInside24Regular } from "@fluentui/react-icons";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
    main: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      ...shorthands.margin("0.5rem"),
      gridGap: "1rem"
    },
  
    card: {
      //width: "400px",
     
      maxWidth: "100%",
      height: "fit-content",
    },
  
    text: {
      ...shorthands.margin(0),
    },
  });

export default function BoardSelectionComponent() {
    const styles = useStyles();
    
    return (
        <div className={styles.main}>
            <Card className={styles.card}>
                <CardHeader
                    image={
                        <ShareScreenPersonOverlayInside24Regular
                            width="32px"
                            height="32px"
                        />
                    }
                    header={
                        <Body1>
                            <b>Schools Unit</b>
                        </Body1>
                    }
                    description={<Caption1>PEL</Caption1>}
                />

                <Divider />
                
                <p className={styles.text}>
                    This board should be used for all communications with schools related to the Parlement européen des lycéens.<br />
                    E-Mail: PEL-SchoolsUnit@lpehq.eu
                </p>

                <CardFooter>
                    <RouterLink to="board/PEL-SchoolsUnit@lpehq.eu">
                        <Button appearance="primary" icon={<Open16Regular />}>
                            Open
                        </Button>
                    </RouterLink>
                </CardFooter>
            </Card>
            <Card className={styles.card}>
                <CardHeader
                    image={
                        <ShareScreenPersonOverlayInside24Regular
                            width="32px"
                            height="32px"
                        />
                    }
                    header={
                        <Body1>
                            <b>Schools Unit</b>
                        </Body1>
                    }
                    description={<Caption1>PEL</Caption1>}
                />
                
                <Divider />
                
                <p className={styles.text}>
                    This board should be used for all communications with schools related to the Parlement européen des lycéens.<br />
                    E-Mail: PEL-SchoolsUnit@lpehq.eu
                </p>

                <CardFooter>
                    <RouterLink to="board/PEL-SchoolsUnit@lpehq.eu">
                        <Button appearance="primary" icon={<Open16Regular />}>
                            Open
                        </Button>
                    </RouterLink>
                </CardFooter>
            </Card>
            <Card className={styles.card}>
                <CardHeader
                    image={
                        <ShareScreenPersonOverlayInside24Regular
                            width="32px"
                            height="32px"
                        />
                    }
                    header={
                        <Body1>
                            <b>Schools Unit</b>
                        </Body1>
                    }
                    description={<Caption1>PEL</Caption1>}
                />
                
                <Divider />
                
                <p className={styles.text}>
                    This board should be used for all communications with schools related to the Parlement européen des lycéens.<br />
                    E-Mail: PEL-SchoolsUnit@lpehq.eu
                </p>

                <CardFooter>
                    <Button appearance="primary" icon={<Open16Regular />}>
                        Open
                    </Button>
                </CardFooter>
            </Card>
            <Card className={styles.card}>
                <CardHeader
                    image={
                        <ShareScreenPersonOverlayInside24Regular
                            width="32px"
                            height="32px"
                        />
                    }
                    header={
                        <Body1>
                            <b>Schools Unit</b>
                        </Body1>
                    }
                    description={<Caption1>PEL</Caption1>}
                />
                
                <Divider />
                
                <p className={styles.text}>
                    This board should be used for all communications with schools related to the Parlement européen des lycéens.<br />
                    E-Mail: PEL-SchoolsUnit@lpehq.eu
                </p>

                <CardFooter>
                    <Button appearance="primary" icon={<Open16Regular />}>
                        Open
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}