import { Body1Stronger, Button, Card, Overflow, OverflowItem, Tab, TabList, makeStyles, shorthands } from "@fluentui/react-components";
import { ArchiveRegular, HourglassThreeQuarterRegular, MailRegular, NewRegular, PersonArrowRightRegular } from "@fluentui/react-icons";
import BoardConversationsComponent from "./BoardConversationsComponent";

const useStyles = makeStyles({
    root: {
        ...shorthands.margin("0.5rem"),
        display: "grid",
        gridTemplateColumns: "1fr 4fr"
    },
    mailButton: {
      ...shorthands.margin("1rem"),
      display: "flex"
    }
  });

  type BoardFolders = {
    id: string;
    name: string;
    icon: React.ReactElement;
  };
  
  const tabs: BoardFolders[] = [
    {
      id: "screening",
      name: "Screening",
      icon: <NewRegular />,
    },
    {
      id: "doing",
      name: "Doing",
      icon: <HourglassThreeQuarterRegular />,
    },
    {
      id: "done",
      name: "Done",
      icon: <ArchiveRegular />,
    },
    {
      id: "escalated",
      name: "Escalated",
      icon: <PersonArrowRightRegular />,
    }
  ];

export default function BoardComponent() {
    const styles = useStyles();
    
    return (
        <div className={styles.root}>
            <div>
                <Button className={styles.mailButton} appearance="primary" icon={<MailRegular />}>
                  New E-Mail
                </Button>

                <Body1Stronger>Folders</Body1Stronger>

                <Overflow minimumVisible={4} overflowAxis="vertical">
                <TabList
                    vertical
                    selectedValue="screening"
                >
                    {tabs.map((tab) => {
                    return (
                        <OverflowItem
                        key={tab.id}
                        id={tab.id}
                        >
                        <Tab value={tab.id} icon={<span>{tab.icon}</span>}>
                            {tab.name}
                        </Tab>
                        </OverflowItem>
                    );
                    })}
                </TabList>
                </Overflow>
            </div>

            <Card>
                <BoardConversationsComponent />
            </Card>
        </div>
    );
}