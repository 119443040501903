import { Body1Stronger, Button, makeStyles, shorthands } from "@fluentui/react-components";
import ConversationToolsComponent from "./ConversationToolsComponent";
import { ArrowHookUpLeftFilled } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import ConversationCardComponent from "./ConversationCardComponent";
import ConversationEditorComponent from "./ConversationEditorComponent";

const useStyles = makeStyles({
    root: {
        ...shorthands.margin("0.5rem"),
    },
    content: {
        ...shorthands.margin("0.5rem", "0rem", "0rem"),
        display: "grid",
        gridTemplateColumns: "3fr 1fr",
        alignItems: "start",
        gridGap: "1rem"
    },
    scrollChild: {
        //overflowX: "scroll"
    }
});

export default function ConversationComponent() {
    const styles = useStyles();
    const navigate = useNavigate();

    return (
        <div className={styles.root}>
            <div>
                <Button icon={<ArrowHookUpLeftFilled />} appearance="outline" onClick={(e) => { e.preventDefault(); navigate(-1); }}>
                    Return to board
                </Button>
                <Body1Stronger style={{ marginLeft: "3rem" }}>Participation Agreeement for PEL 2024 – Demo School</Body1Stronger>
            </div>
            <div className={styles.content}>
                <div className={styles.scrollChild}>
                    <ConversationEditorComponent />
                    <br />
                    <ConversationCardComponent />
                </div>
                
                <ConversationToolsComponent />
            </div>
        </div>
    );
}