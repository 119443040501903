import { useState } from "react";

export default function useAuthProvider() {
    const baseUrl = "https://auth.lpehq.eu/api/";
    const platformId = "LPE-W5S8";

    const getSession = () => {
        const sessionPayload = sessionStorage.getItem('lpeSession');
        if (sessionPayload) {
            const session = JSON.parse(sessionPayload);
            return session;
        }
        return null;
    };

    const [session, setSession] = useState(getSession());

    const initAuth = () => {

        if (window.location.pathname !== "/auth/process") {
            window.location.href = `https://auth.lpehq.eu?platform-id=${platformId}&lang=en`;
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);

        const uuid = urlParams.get('lpeuuid')!
        const process = urlParams.get('authorization_process')!
        const code = urlParams.get('authorization_code')!

        const requestPayload: TokenRedeemPayload = {"platform-id": platformId, "lpeuuid": uuid, "authorization_process": process, "authorization_code": code}

        redeemToken(requestPayload).then((result) => {
            let newSession: Session = {"uuid": result.lpeuuid, "name": "John", "lastname": "doe", "position": "Trainee", "email": "xx", "token": result.token};

            sessionStorage.setItem('lpeSession', JSON.stringify(newSession));
            setSession(newSession);
          }).catch((error) => {
            // Handle any errors that may occur during the fetchToken() call
            console.error(error);
          });
    };

    const redeemToken = async (payload: TokenRedeemPayload) => {
        const url = new URL("platform-confirm-auth", baseUrl);
        const request = new Request(url.toString(), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      
        try {
          const response = await fetch(request);
          if (response.status !== 200) {
            throw new Error("FetchError: bad response");
          }
      
          const data = await response.json();
          if (!data) {
            throw new Error("FetchError: bad token");
          }

          return data as Token;
        } catch (error) {
          // Handle errors as needed
          console.error(error);
          throw error; // You can rethrow the error or handle it as per your requirements
        }
    }

    const logout = () => {
        sessionStorage.removeItem('lpeSession');
        setSession(null);
    };

    return {
        session,
        initAuth,
        logout
    }
}

type Session = {
    uuid: string;
    name: string;
    lastname: string;
    email: string;
    position: string;
    token: string;
};

type TokenRedeemPayload = {
    "platform-id": string;
    lpeuuid: string;
    authorization_process: string;
    authorization_code: string;
};

type Token = {
    lpeuuid: string;
    token: string;
};