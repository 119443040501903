import { DataGrid, DataGridHeader, DataGridRow, DataGridHeaderCell, DataGridBody, DataGridCell, Avatar, TableColumnDefinition, createTableColumn, TableCellLayout } from "@fluentui/react-components";
import { Link as RouterLink } from "react-router-dom";

export default function BoardConversationsComponent() {
    //const styles = useStyles();
    
      type Item = {
        contact: string;
        subject: string;
        preview: string;
        received: string;
      };
      
      const items: Item[] = [
        {
          contact: "John Doe",
          subject: "Participation Agreement – Demo School",
          preview: "Please find it attached",
          received: "12.05.2023"
        },
        {
            contact: "Jeane Forst",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "Luis Lee",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "John Doe",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "Mr DuPont",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "La Proviseure",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "Oula",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "John Doe",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "John Doe",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "John Doe",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
          {
            contact: "John Doe",
            subject: "Participation Agreement – Demo School",
            preview: "Please find it attached",
            received: "12.05.2023"
          },
      ];
      
      const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
          columnId: "Contact",
          renderHeaderCell: () => {
            return "Contact";
          },
          renderCell: (item) => {
            return (
              <TableCellLayout
                media={
                  <Avatar
                    aria-label={item.contact}
                    color="colorful"
                    name={item.contact}
                  />
                }
              >
                {item.contact}
              </TableCellLayout>
            );
          }
        }),
        createTableColumn<Item>({
            columnId: "Subject",
            renderHeaderCell: () => {
              return "Subject";
            },
            renderCell: (item) => {
              return (
                <TableCellLayout>
                  <b>{item.subject}</b><br />
                  {item.preview}...
                </TableCellLayout>
              );
            }
          }),
        createTableColumn<Item>({
          columnId: "Received",
          renderHeaderCell: () => {
            return "Received";
          },
      
          renderCell: (item) => {
            return item.received;
          },
        })
      ];

    const columnSizingOptions = {
        Contact: {
            minWidth: 200,
            defaultWidth: 200,
        },
        Subject: {
            minWidth: 550,
            defaultWidth: 550,
        },
        Received: {
            minWidth: 100,
            defaultWidth: 100,
        },
    };

    return (
        <div style={{ overflowX: "auto" }}>
            <DataGrid
                items={items}
                columns={columns}
                focusMode="composite"
                resizableColumns
                columnSizingOptions={columnSizingOptions}
            >
                <DataGridHeader>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<Item>>
                    {({ item, rowId }) => (
                        <RouterLink to="conversation/12345" style={{textDecoration: "none"}}>
                        <DataGridRow<Item> key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow></RouterLink>
                    )}
                </DataGridBody>
            </DataGrid>
        </div>
    );
}