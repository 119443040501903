import { Body2, Card, CardFooter, Link, Persona, makeStyles, shorthands } from "@fluentui/react-components";
import { List24Filled } from "@fluentui/react-icons";
import { Outlet, Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
    card: {
      ...shorthands.margin("0.5rem", "0.5rem", "1.5rem", "0.5rem")
    },
    cardFooter: {
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        alignItems: "center",
        justifyContent: "space-between"
    }
  });
  
export default function NavComponent() {
    const styles = useStyles();
    
    return (
        <div>
            <Card className={styles.card}>
                <CardFooter className={styles.cardFooter}>
                    <RouterLink to="/"><Link inline={true}><List24Filled /></Link></RouterLink>
                    <Body2>LPE Workspace</Body2>

                    <Persona
                        textAlignment="start"
                        name="John Doe"
                        presence={{ status: "available", outOfOffice: true }}
                        secondaryText="Trainee"
                    />
                </CardFooter>
            </Card>

            <Outlet />
        </div>
    );
}