import { BrandVariants, Theme, createLightTheme, createDarkTheme } from "@fluentui/react-components";

const lpeTheme: BrandVariants = { 
      10: "#090103",
      20: "#300618",
      30: "#500024",
      40: "#65002D",
      50: "#7C0035",
      60: "#93003C",
      70: "#AB0043",
      80: "#C40048",
      90: "#DD004C",
      100: "#F51150",
      110: "#FF4059",
      120: "#FF676A",
      130: "#FF847C",
      140: "#FF9D90",
      150: "#FFB5A6",
      160: "#FFCABD"
    };
    
     export const lpeLightTheme: Theme = {
       ...createLightTheme(lpeTheme), 
    };
    
     export const lpeDarkTheme: Theme = {
       ...createDarkTheme(lpeTheme), 
    };
     
    
    lpeDarkTheme.colorBrandForeground1 = lpeTheme[110];
    lpeDarkTheme.colorBrandForeground2 = lpeTheme[120];