import { Card, CardHeader, Avatar, Caption1, Button, Divider } from "@fluentui/react-components";
import { ArrowReplyAllRegular } from "@fluentui/react-icons";

export default function ConversationCardComponent() {

    return (
        <Card>
            <CardHeader
                image={
                    <Avatar
                        name="John Doe"
                        color="colorful"
                    />
                }
                header={
                    <Caption1>
                        To: PEL-SchoolsUnit@lpehq.eu<br />
                        Cc: j.doe@lpehq.eu
                    </Caption1>
                }
                description={<Caption1>10.06.2023 15:40</Caption1>}
                action={
                    <Button icon={<ArrowReplyAllRegular />} appearance="primary">
                        Reply to all
                    </Button>
                }
            />
            <Divider />

            <div></div>

        </Card>
    );
}