import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BoardComponent from "./Boards/BoardComponent";
import BoardSelectionComponent from "./Boards/BoardSelectionComponent";
import ConversationComponent from "./Conversation/ConversationComponent";
import NavComponent from "./Navigation/NavComponent";
import useAuthProvider from "./Service/AuthProvider";
import CallbackComponent from "./Callback/CallbackComponent";

const router = createBrowserRouter([
    {
        element: <NavComponent />,
        children: [
            {
                path: "/",
                element: <BoardSelectionComponent />,
            },
            {
                path: "/auth/process",
                element: <CallbackComponent />
            },
            {
                path: "/board/:name",
                element: <BoardComponent />,
            },
            {
                path: "/board/:name/conversation/:id",
                element: <ConversationComponent />,
            }
        ]
    }
]);

export default function App() {
    const { session, initAuth } = useAuthProvider();

    if (!session) {
        initAuth();
        return(<p>Loading ...</p>);
    }

    return (
        <RouterProvider router={router} />
    )

}