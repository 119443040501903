import React from 'react';

interface EmailSignatureProps {
  firstName: string;
  lastName: string;
  mainPosition: string;
  secondaryPositions: React.ReactNode;
  email: string;
}

const EmailSignature: React.FC<EmailSignatureProps> = ({ firstName, lastName, mainPosition, secondaryPositions, email }) => {
  return (
    <table>
    <tr>
    <table style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', lineHeight: '15px' }}>
      <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
        <td className="table-wrapper" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
          <table style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }} align="center">
            <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
              <td className="image-wrapper" style={{ maxWidth: '250px', fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', verticalAlign: 'middle' }}>
                <img className="logo" src="https://cdn.lpehq.eu/media/1200/mail/logo.png" alt="LPE Logo" style={{ width: '100%', margin: '8px auto', display: 'block', maxWidth: '250px', textAlign: 'center', paddingBottom: '4px' }} />
              </td>
              <td className="info-wrapper" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingLeft: '15px' }}>
              </td>
            </tr>
            <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
              <td className="info-name" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '15px', color: '#22313F', fontWeight: 'bold', textAlign: 'center' }}>{firstName} {lastName}</td>
            </tr>
            <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
              <td className="info-title" style={{ paddingBottom: '8px', fontFamily: 'Verdana, sans-serif', fontSize: '13px', color: '#22313F', textAlign: 'center' }}>{mainPosition}</td>
            </tr>
            {secondaryPositions}
          </table>
        </td>
      </tr>
      <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
        <td className="info-wrapper" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingLeft: '15px' }}>
          <table style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
            <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
              <td className="info-lpe" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '12px', paddingTop: '18px' }}>Let's promote Europe (LPE)</td>
            </tr>
            <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
              <td className="info-details" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingLeft: '10px', borderLeft: '3px solid #F71851' }}>
                <table style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
                  <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <span className="info-tag" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#F71851', paddingRight: '2px' }}>Phone:</span>
                    </td>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <a href="tel:+4968193356600" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>+49 681 93356600</a>
                    </td>
                  </tr>
                  <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <span className="info-tag" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#F71851', paddingRight: '2px' }}>Mail:</span>
                    </td>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <a href={`mailto:${email}`} style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>{email}</a>
                    </td>
                  </tr>
                  <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <span className="info-tag" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#F71851', paddingRight: '2px' }}>Web:</span>
                    </td>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <a href="https://lpehq.eu" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}>LPEHQ.EU</a>
                    </td>
                  </tr>
                  <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>
                      <span className="info-tag" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#F71851', paddingRight: '2px' }}>Address:</span>
                    </td>
                    <td className="info-general" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', paddingBottom: '4px', paddingRight: '5px' }}>Postfach 65 04 22, D-66143 Saarbrücken</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
        <td className="footer-image" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', padding: '15px', paddingBottom: '10px' }}>
          <img className="footer-banner" src="https://cdn.lpehq.eu/media/2400/mail/banner.png" alt="banner" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', borderRadius: '8px', maxWidth: '100%', maxHeight: '120px' }} />
          <div className="info-social" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', textAlign: 'right', paddingTop: '4px' }}>
            <a href="https://www.facebook.com/LPEHQ" className="social-icon" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit !important', width: '20px', height: '20px', display: 'inline-block', marginRight: '4px', textDecoration: 'none !important', cursor: 'pointer !important' }}>
              <img src="https://cdn.lpehq.eu/media/400/mail/facebook-icon-2x.png" alt="Facebook Logo" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', width: '100%' }} />
            </a>
            <a href="https://twitter.com/_LPEHQ" className="social-icon" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit !important', width: '20px', height: '20px', display: 'inline-block', marginRight: '4px', textDecoration: 'none !important', cursor: 'pointer !important' }}>
              <img src="https://cdn.lpehq.eu/media/400/mail/twitter-icon-2x.png" alt="Twitter Logo" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', width: '100%' }} />
            </a>
            <a href="https://www.youtube.com/channel/UC76F2wJOtlrL3iyRoHI_MBw" className="social-icon" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit !important', width: '20px', height: '20px', display: 'inline-block', marginRight: '4px', textDecoration: 'none !important', cursor: 'pointer !important' }}>
              <img src="https://cdn.lpehq.eu/media/400/mail/youtube-icon-2x.png" alt="YouTube Logo" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', width: '100%' }} />
            </a>
            <a href="https://www.linkedin.com/company/lpehq" className="social-icon" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit !important', width: '20px', height: '20px', display: 'inline-block', marginRight: '4px', textDecoration: 'none !important', cursor: 'pointer !important' }}>
              <img src="https://cdn.lpehq.eu/media/400/mail/linkedin-icon-2x.png" alt="LinkedIn Logo" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', width: '100%' }} />
            </a>
            <a href="https://www.instagram.com/_LPEHQ/" className="social-icon" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: 'inherit !important', width: '20px', height: '20px', display: 'inline-block', marginRight: '4px', textDecoration: 'none !important', cursor: 'pointer !important' }}>
              <img src="https://cdn.lpehq.eu/media/400/mail/instagram-icon-2x.png" alt="Instagram Logo" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F', width: '100%' }} />
            </a>
          </div>
        </td>
      </tr>
    </table>
    </tr>
    <tr>
    <table style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
      <tr className="footer-wrapper" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '12px', color: '#22313F' }}>
        <td className="footer-disclaimer" style={{ fontFamily: 'Verdana, sans-serif', fontSize: '11px', color: '#71757e', textAlign: 'justify', padding: '0px 15px' }}>
          This message contains confidential information intended solely for the attention of the named addressee. It may not be used, disclosed, or copied in any way whatsoever by anyone else than the intended addressee. If you
          received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
        </td>
      </tr>
    </table>
    </tr>
    </table>
  );
};

export default EmailSignature;
